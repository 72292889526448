import React, { useMemo, useState, useEffect } from "react";
import { FaDownload, FaSpinner } from "react-icons/fa";
import { useTable, usePagination } from "react-table";
import Cookies from "universal-cookie";
import Pagination from "../../../CommonComponents/Pagination";
import DataTable from "../../../CommonComponents/DataTable";
import PodFormat from "./podFormat";


const BankPOD = () => {
  const cookies = new Cookies();
  const jwtToken = cookies.get("jwt");
  const [loanData, setLoanData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("loanId");
  const [filterValue, setFilterValue] = useState("");
  const [selectedLoanId, setSelectedLoanId] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [paginationData, setPaginationData] = useState({
    totalDocs: 0,
    totalPages: 0,
    page: 1,
    limit: 10,
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: null,
    prevPage: null,
  });
  const [showList, setShowList] = useState(false); 

  const onClose = () => {
    setShowDetails(false);
  }

  const handleSearch = async () => {
    setShowList(true); 
    setIsLoading(true); 

    try {
      let apiUrl = `${process.env.REACT_APP_CMS_PLATFORM_API_BASE_URL}/bank/pod`;

      if (selectedFilter === "ucid") {
        // Fetch user data based on UCID
        const userDataResponse = await fetch(
          `${process.env.REACT_APP_CMS_PLATFORM_API_BASE_URL}/user/uci/${filterValue}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        if (!userDataResponse.ok) {
          throw new Error(`HTTP error! Status: ${userDataResponse.status}`);
        }

        const userData = await userDataResponse.json();

        // Determine whether to filter by user or organization
        const filterBy = userData.type === "user" ? "user" : "organization";
        const value = userData.result._id;

        apiUrl += `?filter_by=${filterBy}&value=${value}`;
      } else if (selectedFilter === "loanId") {
        // Filter directly by Loan ID
        apiUrl += `?filter_by=loan&value=${filterValue}`;
      }

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setLoanData(data.docs);
      setPaginationData({
        totalDocs: data.totalDocs,
        totalPages: data.totalPages,
        page: data.page,
        limit: data.limit,
        hasNextPage: data.hasNextPage,
        hasPrevPage: data.hasPrevPage,
        nextPage: data.nextPage,
        prevPage: data.prevPage,
      });

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${day}-${month.toString().padStart(2, "0")}-${year} ${hours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
  };

  const handlePOD = (loanId) => {
    console.log("Loan ID is here: ", loanId);
    setSelectedLoanId(loanId);
    setShowDetails(true);
  }


  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ value }) => (
          <div className="px-1 w-24 text-sm">{formatDate(value)}</div>
        ),
      },
      {
        Header: "Name, UCI, PAN",
        id: "userInfo",
        accessor: (d) => {
          // Check if d.user exists, otherwise check for organization data, if none show N/A
          if (!d.user && !d.organization) {
            return {
              name: "N/A",
              position: "N/A",
              systemIdAndPAN: "N/A",
            };
          } else if (!d.user) {
            const orgName = d.organization?.name || "N/A";
            const orgPAN = d.organization?.personal?.pancard || "N/A";
            const system_id = d.organization?.system_id || "N/A";
            return {
              name: orgName,
              // position: "N/A",
              systemIdAndPAN: `${system_id} ${orgPAN} `,
            };
          }

          const { first_name, last_name, system_id, position, personal } =
            d.user;
          const fullName =
            first_name && last_name ? `${first_name} ${last_name}` : "N/A";
          const pancard = personal?.pancard || "N/A";

          return {
            name: fullName,
            position: position || "N/A",
            systemIdAndPAN: `[${system_id || "N/A"}] ${pancard}`,
          };
        },

        Cell: ({ value }) => {
          const { name, position, systemIdAndPAN } = value;
          return (
            <div className="px-2 flex flex-col font-semibold">
              <div className="flex">
                {name !== "N/A"
                  ? name
                    .split(" ")
                    .map((n) =>
                      n ? n.charAt(0).toUpperCase() + n.slice(1) : ""
                    )
                    .join(" ")
                  : "N/A"}
              </div>
              <div>{systemIdAndPAN}</div>
            </div>
          );
        },
      },
      {
        Header: "Loan No.",
        accessor: (d) => {
          if (!d.user && !d.organization) {
            return "N/A";
          } else if (!d.user) {
            return d.loan?.identity_number || "N/A";
          }

          return d.loan?.identity_number || "N/A";
        },
        Cell: ({ value, row }) => {
          let role = "N/A"; // Default role if not found
          if (row.original.loan && row.original.loan.positions) {
            const positions = row.original.loan.positions;
            // Find the role associated with either user or organization
            const userPosition = positions.find(position => position.user);
            const orgPosition = positions.find(position => position.organization);
            if (userPosition) {
              role = userPosition.role;
            } else if (orgPosition) {
              role = orgPosition.role;
            }
          }
          const loanNo = value !== "N/A" ? value : "Null";

          return (
            <div key={row.original._id} style={{ height: "40px" }}>
              <div>
                {loanNo}
                <div className="p-[2px]">
                  <sup
                    className={`border-[1.5px] rounded-md px-1 ml-2 mt-1 text-sm font-semibold ${role === "borrower"
                      ? "border-red-700 text-red-600"
                      : role === "mortgagor"
                        ? "border-blue-700 text-blue-800"
                        : role === "guarantor"
                          ? "border-green-700 text-green-800"
                          : "border-gray-700 text-gray-800"
                      }`}
                  >
                    {role}
                  </sup>
                </div>
              </div>
            </div>
          );
        },

      },

      {
        Header: "Envelope Id",
        accessor: "envelope.document_id",
      },
      {
        Header: "Download All",
        id: "downloadAll",
        accessor: (d) => d.notices,
        Cell: ({ row }) => (
          <button
            className='flex gap-2 items-center ml-5 text-txtclr border border-gray-400 px-4 py-1.5 rounded-md bg-bgclr'
            onClick={() => {
              handlePOD(row.original._id);
            }}
          >
            View
          </button>
        ),
      },
    ],
    []
  );

  const data = React.useMemo(() => loanData);

  const {
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: loanData,
      initialState: { pageIndex: 0 },
      pageCount: paginationData.totalPages,
      manualPagination: true,
    },
    usePagination
  );

  const nextPage = () => {
    const nextPageIndex = pageIndex + 1;
    if (nextPageIndex < paginationData.totalPages) {
      setPaginationData((prevState) => ({
        ...prevState,
        page: nextPageIndex + 1,
      }));
      // fetchData(); // Remove this line
    }
  };

  const previousPage = () => {
    const prevPageIndex = paginationData.page - 1;
    if (prevPageIndex >= 1) {
      setPaginationData((prevState) => ({
        ...prevState,
        page: prevPageIndex,
      }));
    }
  };

  return (
    <div className="flex justify-end bg-bgclr4 min-h-screen py-10">
      {!showDetails ? (
        <div className="rounded-md w-3/4 ml-32">
          {/* Filter inputs */}
          <div className="searchBar-style">
            <select
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
              className="border border-[#EBEBEB] text-gray-400 rounded-md pl-2 pr-5 py-3 mr-2"
            >
              <option value="loanId">Loan ID</option>
              <option value="ucid">UCID</option>
            </select>
            <input
              type="text"
              placeholder={`Filter by ${selectedFilter === "loanId" ? "Loan ID" : "UCID"}`}
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              className="searchBar-input"
            />
            <button
              onClick={handleSearch} 
              className="searchBar-btn"
            >
              Search
            </button>
          </div>

          {showList && ( 
            isLoading ? (
              <div className="flex justify-center items-center h-full">
                <FaSpinner className="animate-spin text-4xl" />
              </div>
            ) : (
              <>
                {loanData.length === 0 ? (
                  <div className="flex justify-center items-center ml-8 h-40">
                    <p>No proof of delivery certificates found. If you've recently sent Reminders or Notices,<br />  please allow some time for the certificates to be generated.<br />For any queries, fell free to reach out to our support team.</p>
                  </div>
                ) : (
                  <div className="w-[975px] mt-6 mb-20">
                    <div className="pt-3 overflow-x-auto mb-10">
                      <DataTable
                        columns={columns}
                        data={data}
                      />
                    </div>
                    <Pagination
                    // hasPrevPage={pagination.hasPrevPage}
                    // hasNextPage={pagination.hasNextPage}
                    // goToPrevPage={goToPrevPage}
                    // goToNextPage={goToNextPage}
                    />
                  </div>
                )}
              </>
            ))}
        </div>
      ) : (
        <div>
          <PodFormat loanId={selectedLoanId} onClose={onClose} />
        </div>
      )}
    </div>

  );
};

export default BankPOD;
