import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdKeyboardArrowDown } from "react-icons/md";
import Cookies from "universal-cookie";
import {  useDecodeJwtToken } from "./TokenDecode";
import { showFailed } from "./SweetAlert";

const Sidebar = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOnboardingMenuOpen, setIsOnboardingMenuOpen] = useState(false);
  const [isBorrowerMenuOpen, setIsBorrowerMenuOpen] = useState(false);
  const [permission, setpermission] = useState([]);

  const cookies = new Cookies();
  const token = cookies.get('jwt');
  const { decodeJwtToken } = useDecodeJwtToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      const decodedData = decodeJwtToken(token);
      console.log("Decode information - > ", decodedData);
      setpermission(decodedData.permissions)
    } else {
      showFailed('Failed...', 'You are not Authorized.');
      navigate('/');
    }
  }, [])

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleOnboardingMenu = () => {
    setIsOnboardingMenuOpen(!isOnboardingMenuOpen);
  };

  const toggleBorrowerMenu = () => {
    setIsBorrowerMenuOpen(!isBorrowerMenuOpen);
  };

  return (
    <>
      <div className="mt-[112px]">
        <button
          onClick={toggleMenu}
          aria-controls="default-sidebar"
          type="button"
          className="inline-flex items-center pt-20 pr-16 ms-3 text-sm  text-gray-500 rounded-lg sm:hidden   "
        >
          {isMenuOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <GiHamburgerMenu size={30} />
          )}
        </button>

        <div
          id="default-sidebar"
          className={`fixed z-50 h-full w-64 bg-bgclr2 dark:bg-gray-800 transition-transform duration-300 ease-in-out transform ${isMenuOpen
            ? "mt-4 pl-12 h-60 bg-black overflow-y-auto"
            : "hidden md:block"
            } sm:relative sm:translate-x-0 md:fixed`}
          aria-label="Sidebar"
        >
          <div className="px-3 py-4 pt-5 overflow-y-auto h-screen text-white">
            <ul className="space-y-2 font-medium">
              {permission?.includes("*") || permission?.includes("cms-roles") ? (
                <li className={`li-style ${location.pathname === "/dashboard/userregistration" ? "bankNavbarStyle" : ""}`}>
                  <Link to="/dashboard/userregistration">
                    <span className="flex-1 ms-3 whitespace-nowrap">CMS User Management</span>
                  </Link>
                </li>
              ) : null}
              {permission?.includes("*") || permission?.includes("roles") ? (
                <li className={`li-style ${location.pathname === "/dashboard/manageRole" ? "bankNavbarStyle" : ""}`}>
                  <Link to="/dashboard/manageRole">
                    <span className="flex-1 ms-3 whitespace-nowrap">CMS Role Management</span>
                  </Link>
                </li>
              ) : null}

              {permission?.includes("*") || permission?.includes("bank") || permission?.includes("branch") ? (
                <li
                  className={`relative li-style ${location.pathname.includes("/dashboard/onboarding") ? "bankNavbarStyle" : ""}`}
                  onMouseEnter={toggleOnboardingMenu}
                  onMouseLeave={toggleOnboardingMenu}
                >
                  <span className="flex ms-3 whitespace-nowrap cursor-pointer">Bank Onboarding</span><MdKeyboardArrowDown className='m-1.5' />
                  {isOnboardingMenuOpen && (
                    <ul className="absolute top-10 left-14 bg-txt-lt-clr text-bgclr py-3 rounded px-3 mt-1 z-50">
                      {permission?.includes("*") || permission?.includes("bank") ? (
                        <li className={`hover:bg-bgclr hover:text-txt-lt-clr pl-4 pr-20  rounded-lg mb-2 ${location.pathname.includes("/dashboard/onboardingbank") ? "bankNavbarStyle" : ""}`}>
                          <Link to="/dashboard/onboardingbank" className="block  py-3">Bank</Link>
                        </li>
                      ) : null}
                      {permission?.includes("*") || permission?.includes("branch") ? (
                        <li className={`hover:bg-bgclr hover:text-txt-lt-clr pl-4 pr-20 rounded-lg ${location.pathname.includes("/dashboard/onboardingbranch") ? "bankNavbarStyle" : ""}`}>
                          <Link to="/dashboard/onboardingbranch" className="block py-3">Branch</Link>
                        </li>
                      ) : null}
                    </ul>
                  )}
                </li>
              ) : null}

              {permission?.includes("*") || permission?.includes("bank-roles") ? (
                <li className={`li-style ${location.pathname === "/dashboard/bankUser" ? "bankNavbarStyle" : ""}`}>
                  <Link to="/dashboard/bankUser">
                    <span className="flex-1 ms-3 whitespace-nowrap">Bank User Management</span>
                  </Link>
                </li>
              ) : null}

              {permission?.includes("*") || permission?.includes("loan") ? (
                <li className={`li-style ${location.pathname === "/dashboard/loanmapping" ? "bankNavbarStyle" : ""}`}>
                  <Link to="/dashboard/loanmapping">
                    <span className="flex-1 ms-3 whitespace-nowrap">Loan Mapping</span>
                  </Link>
                </li>
              ) : null}

              {permission?.includes("*") || permission?.includes("user") || permission?.includes("organization") ? (
                <li
                  className={`relative li-style ${location.pathname.includes("/dashboard/borrower") ? "bankNavbarStyle" : ""}`}
                  onMouseEnter={toggleBorrowerMenu}
                  onMouseLeave={toggleBorrowerMenu}
                >
                  <span className="flex ms-3 whitespace-nowrap cursor-pointer">Borrower Management</span>  <MdKeyboardArrowDown className='m-1.5' />
                  {isBorrowerMenuOpen && (
                    <ul className="absolute top-10 left-14 bg-txt-lt-clr text-bgclr py-3 rounded px-2 mt-1 z-50">
                      {permission?.includes("*") || permission?.includes("user") ? (
                        <li className={`hover:bg-bgclr hover:text-txt-lt-clr pl-4 pr-10 rounded-lg mb-2 ${location.pathname.includes("/dashboard/borrowerindividual") ? "bankNavbarStyle" : ""}`}>
                          <Link to="/dashboard/borrowerindividual" className="block py-3">Individual</Link>
                        </li>
                      ) : null}
                      {permission?.includes("*") || permission?.includes("organization") ? (
                        <li className={`hover:bg-bgclr hover:text-txt-lt-clr pl-4 pr-10 rounded-lg ${location.pathname.includes("/dashboard/borrowerorg") ? "bankNavbarStyle" : ""}`}>
                          <Link to="/dashboard/borrowerorg" className="block py-3">Organization</Link>
                        </li>
                      ) : null}
                    </ul>
                  )}
                </li>
              ) : null}

              {permission?.includes("*") || permission?.includes("pod") ? (
                <li className={`li-style ${location.pathname === "/dashboard/pod" ? "bankNavbarStyle" : ""}`}>
                  <Link to="/dashboard/pod">
                    <span className="flex-1 ms-3 whitespace-nowrap">Proof of Delivery</span>
                  </Link>
                </li>
              ) : null}

              {permission?.includes("*") || permission?.includes("payment") ? (
                <li className={`li-style ${location.pathname === "/dashboard/paymentManage" ? "bankNavbarStyle" : ""}`}>
                  <Link to="/dashboard/paymentManage">
                    <span className="flex-1 ms-3 whitespace-nowrap">Payment Management</span>
                  </Link>
                </li>
              ) : null}

              {permission?.includes("*") || permission?.includes("blog") ? (
                <li className={`li-style ${location.pathname === "/dashboard/blogs" ? "bankNavbarStyle" : ""}`}>
                  <Link to="/dashboard/blogs">
                    <span className="flex-1 ms-3 whitespace-nowrap">Blog Management</span>
                  </Link>
                </li>
              ) : null}

              <li className={`li-style ${location.pathname === "/dashboard/accountSetting" ? "bankNavbarStyle" : ""}`}>
                <Link to="/dashboard/accountSetting">
                  <span className="flex-1 ms-3 whitespace-nowrap">Account Setting</span>
                </Link>
              </li>

              <li className={`li-style ${location.pathname === "/dashboard/help" ? "bankNavbarStyle" : ""}`}>
                <Link to="/dashboard/help">
                  <span className="flex-1 ms-3 whitespace-nowrap">Help</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
