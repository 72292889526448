import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray, useFormik } from 'formik';
import * as Yup from 'yup';
import Popup from 'reactjs-popup';
import { fetchLoanById, fetchNameByUcid } from '../../../../Services/LoanMapping';
import { FaSpinner } from 'react-icons/fa';
import { Loader } from '../../Elements/UpdateLoader';

const LoanAction = ({ isPreviewOpen, operation, addLoading, loanId, handleBankChanges, branchData, setcollatorals, positions, setPositions, options, handleSubmit, handleClose, handleDropdownChange }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState({
        bankName: "",
        branchName: "",
        loanAccountNumber: "",
        borrowerName: "",
        borrowerUciId: "",
        guarantorName: "",
        guarantorUciId: "",
        mortgagorName: "",
        mortgagorUciId: "",
        amount: "",
        interest: "",
        tenure: "",
        loanType: "",
        securityInterest: "",
        valueOfSecurityInterest: "",
        assetType: "",
        particulars: "",
        file: null,
        additionalBorrowers: [],
        additionalGuarantors: [],
        additionalMortgagors: [],
        additionalCollatorals: []
    });

    const structureAndPushPositions = (values, response) => {
        let structuredPositions = [...positions];
        console.log("Structured data: ", values, response);

        const borrower = response.positions.find(pos => pos.role === 'borrower');
        const guarantor = response.positions.find(pos => pos.role === 'guarantor');
        const mortgagor = response.positions.find(pos => pos.role === 'mortgagor');

        const additionalBorrowers = response.positions.filter(pos => pos.role === 'borrower').slice(1).map(pos => ({
            _id: `${pos.user?._id || ''} ${pos.organization?._id || ''}`
        }));
        const additionalGuarantors = response.positions.filter(pos => pos.role === 'guarantor').slice(1).map(pos => ({
            _id: `${pos.user?._id || ''} ${pos.organization?._id || ''}`
        }));
        const additionalMortgagors = response.positions.filter(pos => pos.role === 'mortgagor').slice(1).map(pos => ({
            _id: `${pos.user?._id || ''} ${pos.organization?._id || ''}`
        }));

        // Push borrower details
        if (borrower) {
            structuredPositions.push({
                user: `${borrower?.user?._id  || ''} ${borrower?.organization?._id  || ''}`,
                role: 'borrower',
            });
        }

        // Push additional borrowers with unique identifiers
        additionalBorrowers.forEach((borrower, index) => {
            if (borrower._id) {
                structuredPositions.push({
                    id: `additionalBorrower-${index}`,
                    user: borrower._id,
                    role: 'borrower',
                });
            }
        });

        // Push guarantor details
        if (guarantor) {
            structuredPositions.push({
                user: `${guarantor?.user?._id  || ''} ${guarantor?.organization?._id  || ''}`,
                role: 'guarantor',
            });
        }

        // Push additional guarantors with unique identifiers
        additionalGuarantors.forEach((guarantor, index) => {
            if (guarantor._id) {
                structuredPositions.push({
                    id: `additionalGuarantors-${index}`,
                    user: guarantor._id,
                    role: 'guarantor',
                });
            }
        });

        // Push mortgagor details
        if (mortgagor) {
            structuredPositions.push({
                user: `${mortgagor?.user?._id  || ''} ${mortgagor?.organization?._id  || ''}`,
                role: 'mortgagor',
            });
        }

        // Push additional mortgagors with unique identifiers
        additionalMortgagors.forEach((mortgagor, index) => {
            if (mortgagor._id) {
                structuredPositions.push({
                    id: `additionalMortgagors-${index}`,
                    user: mortgagor._id,
                    role: 'mortgagor',
                });
            }
        });

        setPositions(structuredPositions); // Update the state with structured positions
    };

    console.log("Updated Positions Object is here.....: ", positions);

    const currencies = useMemo(() => ["INR", "EUR", "USD", "JPY", "CAD"], []);

    const validationSchema = Yup.object().shape({
        bankName: Yup.string().required("Bank Name is required"),
        branchName: Yup.string().required("Branch Name is required"),
        loanAccountNumber: Yup.string().required("Loan Account Number is required").max(15, "Loan Account Number must be at most 15 characters"),
        borrowerUciId: Yup.string().required("Borrower UCI ID is required").max(12, "Borrower UCI ID must be at most 12 characters"),
        guarantorUciId: Yup.string().required("Guarantor UCI ID is required").max(12, "Guarantor UCI ID must be at most 12 characters"),
        mortgagorUciId: Yup.string().required("Mortgagor UCI ID is required").max(12, "Mortgagor UCI ID must be at most 12 characters"),
        amount: Yup.number().required("Amount is required").typeError("Amount must be a number"),
        interest: Yup.number().required("Interest Rate is required").typeError("Interest Rate must be a number"),
        tenure: Yup.string().required("Tenure is required"),
        loanType: Yup.string().required("Loan Type is required"),
        valueOfSecurityInterest: Yup.number().required("Value of Security Interest is required").typeError("Value of Security Interest must be a number"),
        assetType: Yup.string().required("Asset Type is required"),
        particulars: Yup.string().required("Collatoral details are required"),
        additionalBorrowers: Yup.array().of(
            Yup.object().shape({
                uciId: Yup.string().required("Co-Borrower UCID No. is required").max(12, "Co-Borrower UCID No. must be at most 12 characters"),
            })
        ),
        additionalGuarantors: Yup.array().of(
            Yup.object().shape({
                uciId: Yup.string().required("Guarantor UCID No. is required").max(12, "Guarantor UCID No. must be at most 12 characters"),
            })
        ),
        additionalMortgagors: Yup.array().of(
            Yup.object().shape({
                uciId: Yup.string().required("Mortgagor UCID No. is required").max(12, "Mortgagor UCID No. must be at most 12 characters"),
            })
        ),
        additionalCollatorals: Yup.array().of(
            Yup.object().shape({
                securityDetails: Yup.string().required("Particulars are required"),
                valueofSecurityInterest: Yup.number().required("Value of Security Interest is required").typeError("Value of Security Interest must be a number"),
                assetType: Yup.string().required("Asset Type is required"),
            })
        ),
    });

    const formik = useFormik({
        initialValues: initialFormValues,
        enableReinitialize: true,
        validationSchema,
    });

    useEffect(() => {
        if (operation === "update") {
            getLoanData();
        }
    }, [operation]);

    useEffect(() => {
        if (initialFormValues) {
            formik.setValues(initialFormValues);
        }
    }, [initialFormValues]);

    const getLoanData = async () => {
        setIsLoading(true);
        try {
            const response = await fetchLoanById(loanId);
            const borrower = response.positions.find(pos => pos.role === 'borrower');
            const guarantor = response.positions.find(pos => pos.role === 'guarantor');
            const mortgagor = response.positions.find(pos => pos.role === 'mortgagor');

            console.log("Updated Positions Object: ", borrower, guarantor, mortgagor);
            const updatedFormValues = {
                bankName: response?.bank?.name || "",
                branchName: response?.branch?.name || "",
                loanAccountNumber: response.identity_number || "",
                borrowerName: borrower ? `${borrower.user?.first_name || ''} ${borrower.user?.last_name || ''} ${borrower.organization?.name || ''}` : "",
                borrowerUciId: `${borrower?.user?.system_id || ""} ${borrower?.organization?.system_id || ""}`,
                guarantorName: guarantor ? `${guarantor.user?.first_name || ''} ${guarantor.user?.last_name || ''} ${guarantor.organization?.name || ''}` : "",
                guarantorUciId: `${guarantor?.user?.system_id || ""} ${guarantor?.organization?.system_id || ""}`,
                mortgagorName: mortgagor ? `${mortgagor.user?.first_name || ''} ${mortgagor.user?.last_name || ''} ${mortgagor.organization?.name || ''}` : "",
                mortgagorUciId: `${mortgagor?.user?.system_id || ""} ${mortgagor?.organization?.system_id || ""}`,
                amount: response.amount || "",
                loanType: response.type || "",
                interest: response.interest || "",
                tenure: response.tenure?.month || "",
                particulars: response.collateral[0]?.detail || "",
                valueOfSecurityInterest: response.collateral[0]?.value || "",
                assetType: response.collateral[0]?.asset || "",
                additionalBorrowers: response.positions.filter(pos => pos.role === 'borrower').slice(1).map(pos => ({
                    uciId: `${pos.user?.system_id || ''} ${pos.organization?.system_id || ''}`,
                    name: `${pos.user?.first_name || ''} ${pos.user?.last_name || ''} ${pos.organization?.name || ''}`
                })),
                additionalGuarantors: response.positions.filter(pos => pos.role === 'guarantor').slice(1).map(pos => ({
                    uciId: `${pos.user?.system_id || ''} ${pos.organization?.system_id || ''}`,
                    name: `${pos.user?.first_name || ''} ${pos.user?.last_name || ''} ${pos.organization?.name || ''}`
                })),
                additionalMortgagors: response.positions.filter(pos => pos.role === 'mortgagor').slice(1).map(pos => ({
                    uciId: `${pos.user?.system_id || ''} ${pos.organization?.system_id || ''}`,
                    name: `${pos.user?.first_name || ''} ${pos.user?.last_name || ''} ${pos.organization?.name || ''}`
                })),
                additionalCollatorals: response.collateral.slice(1).map(coll => ({
                    securityDetails: coll.detail || "",
                    valueofSecurityInterest: coll.value || "",
                    assetType: coll.asset || ""
                })),
            };

            setInitialFormValues(updatedFormValues);
            structureAndPushPositions(updatedFormValues, response);
        } catch (error) {
            console.error("Error fetching loan data: ", error);
        }
        setIsLoading(false);
    };


    const getUserDetails = async (ucid, role, setFieldCallback, identifier) => {
        const response = await fetchNameByUcid(ucid);
        let userName;
        let updatedPositions = [...positions];
        if (response?.type) {
            if (response?.type === "organization") {
                const { name, _id } = response.result;
                userName = name;
                updatedPositions.push({
                    id: identifier,
                    organization: _id,
                    role: role,
                });
            } else if (response?.type === "user") {
                const { first_name, last_name } = response.result;
                userName = `${first_name} ${last_name}`;
                updatedPositions.push({
                    id: identifier,
                    user: response.result._id,
                    role: role,
                });
            }
            setPositions(updatedPositions);
            setFieldCallback(userName);
        }
    };

    const handleEntityUCIDChange = useCallback(
        async (ucid, role, setFieldCallback, identifier) => {
            await getUserDetails(ucid, role, setFieldCallback, identifier);
        },
        [getUserDetails]
    );

    const handleFieldChange = (setFieldValue, fieldName, ucid, role, identifier) => {
        if (ucid) {
            handleEntityUCIDChange(ucid, role, (name) => {
                setFieldValue(fieldName, name);
            }, identifier);
        }
    };

    const removePositionById = (identifier) => {
        setPositions((prevPositions) => prevPositions.filter((pos) => pos.id !== identifier));
    };

    const handleRemoveCollateral = (index) => {
        setcollatorals(prevState => prevState.filter((_, i) => i !== index));
    };

    const inputUCIDChange = (e) => {
        e.target.value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    };

    const inputChangeNumber = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
    };

    return (
        <Popup open={isPreviewOpen} modal closeOnDocumentClick={false}>
            {(close) => (
                <div className="bg-gray-500 px-28 py-10 fixed inset-0 flex bg-opacity-50 justify-center items-center overflow-y-scroll">
                    {isLoading ? (
                        Loader
                    ) : (
                        <div className="container p-[0.2px] pt-16 mx-20 mt-20 rounded-md bg-white min-h-auto max-h-screen">
                            <Formik
                                initialValues={initialFormValues || {
                                    bankName: "",
                                    branchName: "",
                                    loanAccountNumber: "",
                                    borrowerName: "",
                                    borrowerUciId: "",
                                    guarantorName: "",
                                    guarantorUciId: "",
                                    mortgagorName: "",
                                    mortgagorUciId: "",
                                    amount: "",
                                    interest: "",
                                    tenure: "",
                                    loanType: "",
                                    securityInterest: "",
                                    valueOfSecurityInterest: "",
                                    assetType: "",
                                    particulars: "",
                                    file: null,
                                    additionalBorrowers: [],
                                    additionalGuarantors: [],
                                    additionalMortgagors: [],
                                    additionalCollatorals: []
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {(formikProps) => (
                                    <Form>
                                        <div className="bg-bg-form pb-8 px-16 min-h-auto">
                                            <div className="flex justify-end">
                                                <button
                                                    type="submit"
                                                    onClick={() => {
                                                        handleClose();
                                                        close();
                                                        setInitialFormValues(null);
                                                        setPositions([]);
                                                    }}
                                                    className="text-2xl font-bold"
                                                >
                                                    X
                                                </button>
                                            </div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <h1 className="font-bold text-xl text-gray-500">
                                                        User Details
                                                    </h1>
                                                </div>
                                                <div>
                                                    <span className="text-red-700 font-semibold text-md">
                                                        [* Indicates a required field]
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="field-split">
                                                <div>
                                                    <label htmlFor="bankName" className="pr-12">Select Bank</label>
                                                    <select
                                                        id="bankName"
                                                        name="bankName"
                                                        className="input-style rounded-md"
                                                        onChange={(e) => {
                                                            formikProps.setFieldValue("bankName", e.target.value);
                                                            handleBankChanges(e);
                                                        }}
                                                    >
                                                        <option value="default">Select a bank</option>
                                                        {options?.map((option) => (
                                                            <option key={option?._id} value={option?._id}>{option?.name}</option>
                                                        ))}
                                                    </select>
                                                    <ErrorMessage className="error-style" name="bankName" component="div" />
                                                </div>

                                                <div>
                                                    <label htmlFor="branchName" className="pr-12">Select Branch</label>
                                                    <select
                                                        id="branchName"
                                                        name="branchName"
                                                        className="input-style rounded-md"
                                                        onChange={(e) => {
                                                            formikProps.setFieldValue("branchName", e.target.value);
                                                        }}
                                                    >
                                                        <option value="">Select a branch</option>
                                                        {branchData?.map((option) => (
                                                            <option key={option?._id} value={option?._id}>{option?.name}</option>
                                                        ))}
                                                    </select>
                                                    <ErrorMessage className="error-style" name="branchName" component="div" />
                                                </div>
                                            </div>

                                            {/* Loan Account Number */}
                                            <div className="mt-4">
                                                <div>
                                                    <label htmlFor="loanAccountNumber" className='label-style'>
                                                        Loan Account Number<span className="text-red-800">*</span>
                                                    </label>
                                                    <Field maxLength={12} onInput={(e) => inputChangeNumber(e)} className="input-style" name="loanAccountNumber" type="text" placeholder="Enter Loan Account Number" />
                                                    <ErrorMessage className="error-style" name="loanAccountNumber" component="div" />
                                                </div>
                                            </div>

                                            {/* Borrower Information */}
                                            <div className="field-split">
                                                <div>
                                                    <label htmlFor="borrowerUciId" className='label-style'>
                                                        Borrower UCID<span className="text-red-800">*</span>
                                                    </label>
                                                    <Field onInput={(e) => inputUCIDChange(e)} onBlur={(e) => handleFieldChange(formikProps.setFieldValue, "borrowerName", e.target.value, "borrower")} className="input-style" name="borrowerUciId" type="text" placeholder="Enter Borrower UCI ID" />
                                                    <ErrorMessage className="error-style" name="borrowerUciId" component="div" />
                                                </div>

                                                <div>
                                                    <label htmlFor="borrowerName" className='label-style'>
                                                        Borrower Name<span className="text-red-800">*</span>
                                                    </label>
                                                    <Field className="input-style bg-gray-200" disabled name="borrowerName" type="text" placeholder="Enter Borrower Name" />
                                                    <ErrorMessage className="error-style" name="borrowerName" component="div" />
                                                </div>
                                            </div>

                                            {/* Additional Borrowers */}
                                            <FieldArray name="additionalBorrowers">
                                                {({ push, remove }) => (
                                                    <div>
                                                        {formikProps.values.additionalBorrowers?.map((_, index) => (
                                                            <div key={index}>
                                                                <div className="field-split">
                                                                    <div>
                                                                        <label htmlFor={`additionalBorrowers.${index}.uciId`} className='label-style'>
                                                                            Additional Borrower UCID<span className="text-red-800">*</span>
                                                                        </label>
                                                                        <Field onInput={(e) => inputUCIDChange(e)} onBlur={(e) => handleFieldChange(formikProps.setFieldValue, `additionalBorrowers.${index}.name`, e.target.value, "borrower", `additionalBorrower-${index}`)} className="input-style" name={`additionalBorrowers.${index}.uciId`} type="text" placeholder="Enter Co-Borrower UCID" />
                                                                        <ErrorMessage className="error-style" name={`additionalBorrowers.${index}.uciId`} component="div" />
                                                                    </div>

                                                                    <div>
                                                                        <label htmlFor={`additionalBorrowers.${index}.name`} className='label-style'>
                                                                            Additional Borrower Name<span className="text-red-800">*</span>
                                                                        </label>
                                                                        <Field className="input-style bg-gray-200" disabled name={`additionalBorrowers.${index}.name`} type="text" placeholder="Enter Co-Borrower Name" />
                                                                        <ErrorMessage className="error-style" name={`additionalBorrowers.${index}.name`} component="div" />
                                                                    </div>
                                                                </div>
                                                                <div className='flex justify-end'>
                                                                    <button className='text-red-600 underline text-xl' type="button" onClick={() => { remove(index); removePositionById(`additionalBorrower-${index}`); }}>Remove</button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className='flex justify-end'>
                                                            <button type="button" className='btn-style' onClick={() => push({ uciId: "" })}>Add Borrower</button>
                                                        </div>
                                                    </div>
                                                )}
                                            </FieldArray>

                                            {/* Guarantor Information */}
                                            <div className="field-split">
                                                <div>
                                                    <label htmlFor="guarantorUciId" className='label-style'>
                                                        Guarantor UCI ID<span className="text-red-800">*</span>
                                                    </label>
                                                    <Field onInput={(e) => inputUCIDChange(e)} onBlur={(e) => handleFieldChange(formikProps.setFieldValue, "guarantorName", e.target.value, "guarantor")} className="input-style" name="guarantorUciId" type="text" placeholder="Enter Guarantor UCI ID" />
                                                    <ErrorMessage className="error-style" name="guarantorUciId" component="div" />
                                                </div>

                                                <div>
                                                    <label htmlFor="guarantorName" className='label-style'>
                                                        Guarantor Name<span className="text-red-800">*</span>
                                                    </label>
                                                    <Field className="input-style bg-gray-200" disabled name="guarantorName" type="text" placeholder="Enter Guarantor Name" />
                                                    <ErrorMessage className="error-style" name="guarantorName" component="div" />
                                                </div>
                                            </div>

                                            {/* Additional Guarantors */}
                                            <FieldArray name="additionalGuarantors">
                                                {({ push, remove }) => (
                                                    <div>
                                                        {formikProps.values.additionalGuarantors.map((_, index) => (
                                                            <div key={index}>
                                                                <div className="field-split">
                                                                    <div>
                                                                        <label htmlFor={`additionalGuarantors.${index}.uciId`} className='label-style'>
                                                                            Additional Guarantor UCID<span className="text-red-800">*</span>
                                                                        </label>
                                                                        <Field onInput={(e) => inputUCIDChange(e)} onBlur={(e) => handleFieldChange(formikProps.setFieldValue, `additionalGuarantors.${index}.name`, e.target.value, "guarantor", `additionalGuarantors-${index}`)} className="input-style" name={`additionalGuarantors.${index}.uciId`} type="text" placeholder="Enter Guarantor UCID" />
                                                                        <ErrorMessage className="error-style" name={`additionalGuarantors.${index}.uciId`} component="div" />
                                                                    </div>

                                                                    <div>
                                                                        <label htmlFor={`additionalGuarantors.${index}.name`} className='label-style'>
                                                                            Additional Guarantor Name<span className="text-red-800">*</span>
                                                                        </label>
                                                                        <Field className="input-style bg-gray-200" disabled name={`additionalGuarantors.${index}.name`} type="text" placeholder="Enter Guarantor Name" />
                                                                        <ErrorMessage className="error-style" name={`additionalGuarantors.${index}.name`} component="div" />
                                                                    </div>
                                                                </div>
                                                                <div className='flex justify-end'>
                                                                    <button className='text-red-600 underline text-xl' type="button" onClick={() => { remove(index); removePositionById(`additionalGuarantors-${index}`); }}>Remove</button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className='flex justify-end'>
                                                            <button type="button" className='btn-style' onClick={() => push({ uciId: "" })}>Add Guarantor</button>
                                                        </div>
                                                    </div>
                                                )}
                                            </FieldArray>

                                            {/* Mortgagor Information */}
                                            <div className="field-split">
                                                <div>
                                                    <label htmlFor="mortgagorUciId" className='label-style'>
                                                        Mortgagor UCI ID<span className="text-red-800">*</span>
                                                    </label>
                                                    <Field onInput={(e) => inputUCIDChange(e)} onBlur={(e) => handleFieldChange(formikProps.setFieldValue, "mortgagorName", e.target.value, "mortgagor")} className="input-style" name="mortgagorUciId" type="text" placeholder="Enter Mortgagor UCI ID" />
                                                    <ErrorMessage className="error-style" name="mortgagorUciId" component="div" />
                                                </div>

                                                <div>
                                                    <label htmlFor="mortgagorName" className='label-style'>
                                                        Mortgagor Name<span className="text-red-800">*</span>
                                                    </label>
                                                    <Field className="input-style bg-gray-200" disabled name="mortgagorName" type="text" placeholder="Enter Mortgagor Name" />
                                                    <ErrorMessage className="error-style" name="mortgagorName" component="div" />
                                                </div>
                                            </div>

                                            {/* Additional Mortgagors */}
                                            <FieldArray name="additionalMortgagors">
                                                {({ push, remove }) => (
                                                    <div>
                                                        {formikProps.values.additionalMortgagors.map((_, index) => (
                                                            <div key={index}>
                                                                <div className="field-split">
                                                                    <div>
                                                                        <label htmlFor={`additionalMortgagors.${index}.uciId`} className='label-style'>
                                                                            Additional Mortgagor UCID<span className="text-red-800">*</span>
                                                                        </label>
                                                                        <Field onInput={(e) => inputUCIDChange(e)} onBlur={(e) => handleFieldChange(formikProps.setFieldValue, `additionalMortgagors.${index}.name`, e.target.value, "mortgagor", `additionalMortgagor-${index}`)} className="input-style" name={`additionalMortgagors.${index}.uciId`} type="text" placeholder="Enter Mortgagor UCID" />
                                                                        <ErrorMessage className="error-style" name={`additionalMortgagors.${index}.uciId`} component="div" />
                                                                    </div>

                                                                    <div>
                                                                        <label htmlFor={`additionalMortgagors.${index}.name`} className='label-style'>
                                                                            Additional Mortgagor Name<span className="text-red-800">*</span>
                                                                        </label>
                                                                        <Field className="input-style bg-gray-200" disabled name={`additionalMortgagors.${index}.name`} type="text" placeholder="Enter Mortgagor Name" />
                                                                        <ErrorMessage className="error-style" name={`additionalMortgagors.${index}.name`} component="div" />
                                                                    </div>
                                                                </div>
                                                                <div className='flex justify-end'>
                                                                    <button className='text-red-600 underline text-xl' type="button" onClick={() => { remove(index); removePositionById(`additionalMortgagor-${index}`); }}>Remove</button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className='flex justify-end'>
                                                            <button type="button" className='btn-style' onClick={() => push({ uciId: "" })}>Add Mortgagor</button>
                                                        </div>
                                                    </div>
                                                )}
                                            </FieldArray>

                                            <div className="field-split">
                                                <div>
                                                    <label htmlFor="amount" className='label-style'>Amount<span className="text-red-800">*</span></label>
                                                    <div className='flex'>
                                                        <Field onInput={(e) => inputChangeNumber(e)} className="input-style" name="amount" type="number" placeholder="Enter Loan Amount" />
                                                        <Field as="select" name="currency" className="ml-2 rounded-md bg-gray-100 text-gray-500">
                                                            {currencies.map((currency) => (
                                                                <option key={currency} value={currency}>
                                                                    {currency}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage className="error-style" name="amount" component="div" />
                                                </div>

                                                <div>
                                                    <label htmlFor="interest" className='label-style'>Interest Rate<span className="text-red-800">*</span></label>
                                                    <Field onInput={(e) => inputChangeNumber(e)} className="input-style" name="interest" type="number" placeholder="Enter Interest Rate" />
                                                    <ErrorMessage className="error-style" name="interest" component="div" />
                                                </div>
                                            </div>

                                            <div className="field-split">
                                                <div>
                                                    <label htmlFor="tenure" className='label-style'>Tenure<span className="text-red-800">*</span></label>
                                                    <Field as="select" className="input-style" name="tenure" type="text" placeholder="Enter Loan Tenure" >
                                                        {[...Array(361).keys()].map((month) => (
                                                            <option key={month} value={month}>
                                                                {month}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage className="error-style" name="tenure" component="div" />
                                                </div>

                                                <div>
                                                    <label htmlFor="loanType" className='label-style'>Loan Type<span className="text-red-800">*</span></label>
                                                    <Field as="select" className="input-style" name="loanType">
                                                        <option value="">Select a loan type</option>
                                                        <option value="gold">Home Loan</option>
                                                        <option value="vehicle">Vehicle Loan</option>
                                                        <option value="auto">Commercial Vehicle Loan</option>
                                                        <option value="personal">Personal Loan</option>
                                                        <option value="business">Business Loan</option>
                                                        <option value="education">Education Loan</option>
                                                        <option value="property">Loan against Property</option>
                                                        <option value="others">Others</option>
                                                    </Field>
                                                    <ErrorMessage className="error-style" name="loanType" component="div" />
                                                </div>
                                            </div>

                                            {/* Collateral Fields */}
                                            <div className="mt-4">
                                                <label htmlFor="particulars" className="pr-12">
                                                    Collatoral Details
                                                    <span className="text-red-800">*</span>
                                                </label>
                                                <Field as="textarea" id="particulars" name="particulars" placeholder="Collatoral Details........." rows="4" className="input-style rounded-md" />
                                                <ErrorMessage className="error-style" name="particulars" component="div" />
                                            </div>

                                            <div className="field-split">
                                                <div>
                                                    <label htmlFor="valueOfSecurityInterest" className='label-style'>Collatoral Value<span className="text-red-800">*</span></label>
                                                    <Field onInput={(e) => inputChangeNumber(e)} className="input-style" name="valueOfSecurityInterest" type="text" placeholder="Enter Collatoral Value" />
                                                    <ErrorMessage className="error-style" name="valueOfSecurityInterest" component="div" />
                                                </div>
                                                <div>
                                                    <label htmlFor="assetType" className='label-style'>Asset Type<span className="text-red-800">*</span></label>
                                                    <Field as="select" className="input-style" name="assetType" type="text" placeholder="Enter Asset Type">
                                                        <option value="">Select Assets Type</option>
                                                        <option value="Real Estate">Movable Property</option>
                                                        <option value="Vehicles">Immovable Property</option>
                                                        <option value="Policies">Policies</option>
                                                        <option value="Deposit">Deposit Accounts</option>
                                                        <option value="others">Other</option>
                                                    </Field>
                                                    <ErrorMessage className="error-style" name="assetType" component="div" />
                                                </div>
                                            </div>

                                            <FieldArray name="additionalCollatorals">
                                                {({ push, remove }) => (
                                                    <div>
                                                        {formikProps.values.additionalCollatorals.map((collateral, index) => (
                                                            <div key={index}>
                                                                <div className='mt-4'>
                                                                    <label htmlFor={`additionalCollatorals.${index}.securityDetails`} className='label-style'>
                                                                        Additional Collateral Details<span className="text-red-800">*</span>
                                                                    </label>
                                                                    <Field as="textarea" className="input-style" name={`additionalCollatorals.${index}.securityDetails`} placeholder="Collateral Details..." />
                                                                    <ErrorMessage className="error-style" name={`additionalCollatorals.${index}.securityDetails`} component="div" />
                                                                </div>
                                                                <div className="field-split">
                                                                    <div>
                                                                        <label htmlFor={`additionalCollatorals.${index}.valueofSecurityInterest`} className='label-style'>
                                                                            Additional Collateral Value<span className="text-red-800">*</span>
                                                                        </label>
                                                                        <Field onInput={(e) => inputChangeNumber(e)} className="input-style" name={`additionalCollatorals.${index}.valueofSecurityInterest`} type="text" placeholder="Collateral value"
                                                                        />
                                                                        <ErrorMessage className="error-style" name={`additionalCollatorals.${index}.valueofSecurityInterest`} component="div" />
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor={`additionalCollatorals.${index}.assetType`} className='label-style'>
                                                                            Asset Type<span className="text-red-800">*</span>
                                                                        </label>
                                                                        <Field as="select" className="input-style" name={`additionalCollatorals.${index}.assetType`} placeholder="Enter Asset Type"
                                                                        >
                                                                            <option value="">Select Asset Type</option>
                                                                            <option value="Real Estate">Movable Property</option>
                                                                            <option value="Vehicles">Immovable Property</option>
                                                                            <option value="Policies">Policies</option>
                                                                            <option value="Deposit">Deposit Accounts</option>
                                                                            <option value="others">Other</option>
                                                                        </Field>
                                                                        <ErrorMessage
                                                                            className="error-style"
                                                                            name={`additionalCollatorals.${index}.assetType`}
                                                                            component="div"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='flex justify-end'>
                                                                    <button className='text-red-600 underline text-xl' type="button" onClick={() => { remove(index); handleRemoveCollateral(index) }}>Remove</button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className='flex justify-end'>
                                                            <button type="button" className='btn-style' onClick={() => push({ securityDetails: "", valueofSecurityInterest: "", assetType: "" })}>Add Collateral</button>
                                                        </div>
                                                    </div>
                                                )}
                                            </FieldArray>

                                            <div className='flex justify-center my-8'>
                                                <button type="submit" className="px-12 py-2 text-white bg-bgclr w-full rounded-md font-bold hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200">
                                                    {!addLoading ? 'Upload' : <FaSpinner className="animate-spin text-3xl" />}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>

                        </div>
                    )}
                </div>
            )
            }
        </Popup >
    );
};

export default React.memo(LoanAction);