import React, { useState, useEffect } from "react";
import { Popup } from "reactjs-popup";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { FaSpinner } from "react-icons/fa";
import { getCMSUserById } from "../../../../Services/CmsUserManagement";
import { Loader } from "../../Elements/UpdateLoader";

const tabs = ['cms-roles', 'roles', 'bank-roles', 'bank', 'branch', 'loan', 'user', 'organization', 'pod', 'blog', 'payment'];

const UserActions = ({ isPreviewOpen, onClose, userId, roles, operation, handleClose, handleSubmit, handleUpdate, formData, isLoadingUpdate }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updateUserValue, setUpdatedUserValues] = useState([]);
    const [allChecked, setAllChecked] = useState(false);

    useEffect(() => {
        if (operation === 'update') {
            const getUserById = async () => {
                setIsLoading(true);
                try {
                    const data = await getCMSUserById(userId);
                    let permission;
                    if (data?.permissions.includes("*")) {
                        permission = ["all"];
                        setAllChecked(true);
                    } else {
                        permission = data?.permissions;
                    }
                    setUpdatedUserValues({
                        email: data?.email,
                        name: data?.name,
                        phone_number: data?.phone_number,
                        gender: data?.gender,
                        role: data?.role.toLowerCase(),
                        permission: permission
                    });
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                    throw new Error('Error retrieving user data:', error);
                }
            };
            getUserById();
            setIsEditing(true);
        }
    }, [operation]);

    const validationSchema = Yup.object({
        name: Yup.string().required('Entity Name is Required').min(3, 'Must be at least 3 characters'),
        email: Yup.string().required('Email is Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email is Invalid'),
        phone_number: Yup.string().required('Mobile Number is Required').matches(/^[0-9]{10}$/, 'Mobile Number is Invalid'),
        gender: Yup.string().required('Gender is Required'),
        role: Yup.string().required('Role is Required'),
    });

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        let updatedPermissions = [...(updateUserValue.permission || [])];

        if (value === 'all') {
            setAllChecked(checked);
            updatedPermissions = checked ? ['*'] : [];
        } else {
            if (checked) {
                updatedPermissions.push(value);
            } else {
                updatedPermissions = updatedPermissions.filter(permission => permission !== value);
            }
            setAllChecked(false);
        }

        setUpdatedUserValues(prevState => ({
            ...prevState,
            permission: updatedPermissions
        }));
    };

    return (
        <>
            <Popup open={isPreviewOpen} modal closeOnDocumentClick={false}>
                {(close) => (
                    <div
                        className="bg-gray-500 fixed inset-0 flex bg-opacity-50  h-screen justify-center items-center overflow-y-scroll"
                        style={{ WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}
                    >
                        {isLoading ? (
                            Loader
                        ) : (
                            <div className="flex container justify-center items-center mt-56 p-8 rounded-md mx-auto min-h-screen max-h-screen ">
                                <div className="w-[500px] mx-auto">
                                    <Formik
                                        initialValues={isEditing ? updateUserValue : formData}
                                        validationSchema={validationSchema}
                                        onSubmit={values => isEditing ? handleUpdate(values, updateUserValue.permission) : handleSubmit(values, updateUserValue.permission)}
                                    >
                                        {(formikProps) => (
                                            <Form>
                                                <div className="bg-bg-form px-16 py-16 mt-56 text-tbl-txt-clr">
                                                    <div className="flex justify-end">
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleClose();
                                                                close();
                                                                setUpdatedUserValues(null);
                                                            }}
                                                            className="text-2xl font-bold"
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                    <h2 className="text-xl font-semibold pb-6">
                                                        {isEditing
                                                            ? "Update CMS User"
                                                            : "Add CMS User"}
                                                    </h2>
                                                    <div className="mb-6">
                                                        <label htmlFor="email" className="block text-sm font-medium" >
                                                            Authorized Email ID<span className="text-red-600 font-bold">*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="email"
                                                            name="email"
                                                            placeholder="Enter email Id"
                                                            className="input-style"
                                                        />
                                                        <ErrorMessage name="email" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                    <div className="mb-6">
                                                        <label
                                                            htmlFor="phone_number"
                                                            className="block text-sm font-medium"
                                                        >
                                                            Authorized Phone Number<span className="text-red-600 font-bold">*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="phone_number"
                                                            name="phone_number"
                                                            placeholder="Enter phone number"
                                                            maxLength={10}
                                                            className="input-style"
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                            }}
                                                        />
                                                        <ErrorMessage name="phone_number" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                    <div className="mb-6">
                                                        <label
                                                            htmlFor="name"
                                                            className="block text-sm font-medium text-txtclr2"
                                                        >
                                                            Authorized Person's Name<span className="text-red-600 font-bold">*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            placeholder="Enter Name"
                                                            id="name"
                                                            name="name"
                                                            className="input-style"
                                                        />
                                                        <ErrorMessage name="name" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    <div className='mb-6'>
                                                        <label htmlFor="gender" className="block text-sm font-medium text-txtclr2">
                                                            Gender<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field
                                                            as="select"
                                                            id="gender"
                                                            name="gender"
                                                            className="input-style"
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                            <option value="other">Other</option>
                                                        </Field>
                                                        <ErrorMessage name="gender" component="div" className="text-red-600 font-semibold" />
                                                    </div>

                                                    <div className='mb-6'>
                                                        <label htmlFor="role" className="block text-sm font-medium text-txtclr2">
                                                            Role<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field
                                                            as="select"
                                                            id="role"
                                                            name="role"
                                                            className="input-style"
                                                        >
                                                            <option value="">Select Role</option>
                                                            {roles?.map((option) => (
                                                                <option key={option?._id} value={option?.name}>
                                                                    {option?.name}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                        <ErrorMessage name="role" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="role" className="block text-sm font-medium text-txtclr2">
                                                            Tab Access<span className='text-red-800'>*</span>
                                                        </label>
                                                        <div className="ml-5 mt-2 flex gap-10">
                                                            <div>
                                                                <div className="flex items-center mb-2">
                                                                    <Field
                                                                        id="all"
                                                                        name="tab_access"
                                                                        value="all"
                                                                        type="checkbox"
                                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                                        checked={allChecked}
                                                                        onChange={handleCheckboxChange}
                                                                    />
                                                                    <label htmlFor="all" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                                        All
                                                                    </label>
                                                                </div>
                                                                {tabs.slice(0, 6).map((permission) => (
                                                                    <div className="flex items-center mb-2" key={permission}>
                                                                        <Field
                                                                            id={permission}
                                                                            name="tab_access"
                                                                            value={permission}
                                                                            type="checkbox"
                                                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                                            checked={(updateUserValue && updateUserValue.permission) ? updateUserValue.permission.includes(permission) : false}
                                                                            onChange={handleCheckboxChange}
                                                                            disabled={allChecked}
                                                                        />
                                                                        <label htmlFor={permission} className={`${allChecked ? 'text-gray-400' : 'text-gray-900 dark:text-gray-300'} ms-2 text-sm font-medium `}>
                                                                            {permission}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div>
                                                                {tabs.slice(6, 16).map((permission) => (
                                                                    <div className="flex items-center mb-2" key={permission}>
                                                                        <Field
                                                                            id={permission}
                                                                            name="tab_access"
                                                                            value={permission}
                                                                            type="checkbox"
                                                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                                            checked={(updateUserValue && updateUserValue.permission) ? updateUserValue.permission.includes(permission) : false}
                                                                            onChange={handleCheckboxChange}
                                                                            disabled={allChecked}
                                                                        />
                                                                        <label htmlFor={permission} className={`${allChecked ? 'text-gray-400' : 'text-gray-900 dark:text-gray-300'} ms-2 text-sm font-medium `}>
                                                                            {permission}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="flex justify-center ">
                                                        <button
                                                            type="submit"
                                                            className="px-12 mt-8 py-2 text-txt-lt-clr bg-bgclr2 rounded-full font-bold hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200"
                                                        >
                                                            {isLoadingUpdate ? (
                                                                <FaSpinner className="animate-spin text-3xl" />
                                                            ) : (
                                                                "Submit"
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Popup>
        </>
    );
};

export default UserActions;
