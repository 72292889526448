import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CMS_PLATFORM_API_BASE_URL,
});
console.log('NEXT_PUBLIC_API_BASE_URL', process.env.REACT_APP_CMS_PLATFORM_API_BASE_URL);
axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

axiosInstance.interceptors.request.use(async (config) => {
  console.log("Url ---> ", config.url);
  const url = config.url;
  const endPoints = url.split('/')[1].split('?')[0];
  console.log("End Point - ", endPoints);
  const cmsJwtToken = cookies.get('jwt');
  if (cmsJwtToken) {
    config.headers.Authorization = `Bearer ${cmsJwtToken}`;
    switch (endPoints) {
      case "bank":
        config.headers['module-id'] = 'bank';
        break;
      case "branch":
        config.headers['module-id'] = 'branch';
        break;
      case "roles":
        config.headers['module-id'] = 'roles';
        break;
      case "user":
        config.headers['module-id'] = 'user';
        break;
      case "organization":
        config.headers['module-id'] = 'organization';
        break;
      case "loan":
        config.headers['module-id'] = 'loan';
        break;
      case "bank-roles":
        config.headers['module-id'] = 'bank-roles';
        break;
      case "blogs":
        config.headers['module-id'] = 'blogs';
        break;
      case "cms":
        config.headers['module-id'] = 'payment';
        break;
      case "dashboard":
        config.headers['module-id'] = 'dashboard';
        break;
      default:
        break;
    }
  }
  return config;
});

export default axiosInstance;