import React, { useEffect, useState } from 'react'
import { Button1 } from '../../../CommonComponents/Button'
import DataTable from '../../../CommonComponents/DataTable';
import Pagination from '../../../CommonComponents/Pagination';
import Loader from '../../../CommonComponents/Loader';
import EmptyDataAlert from '../../../CommonComponents/EmptyDataAlert';
import { getBankByName, getBankList } from '../../../../Services/BranchOnboarding';
import { useDispatch } from 'react-redux';
import { fetchLoanDetails } from '../../../../Services/LoanMapping';
import LoanAction from './LoanAction';
import { addLoanDetails, updateLoanDetails } from '../../../../Reducer/LoanMappingReducer';
import { showFailed } from '../../../CommonComponents/SweetAlert';
import { loanPagination } from '../../../../Services/Pagination';

const LoanMapTable = () => {

    const dispatch = useDispatch();
    const [loanData, setLoanData] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [pagination, setPagination] = useState(null);
    const [options, setOptions] = useState([]);
    const [isBankName, setBankName] = useState();
    const [branchData, setBranchData] = useState([]);
    const [operation, setOperation] = useState('');
    const [isPreviewOpen, setPreviewOpen] = useState(false);
    const [positions, setPositions] = useState([]);
    const [collatorals, setcollatorals] = useState([]);
    const [addLoading, setAddLoading] = useState(false);
    const [loanId, setLoanId] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getBankList();
                setOptions(data);
                console.log("Roles Data is here:---", data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [])

    const fetchBranchesData = async (bankName) => {
        setError(null);
        try {
            const data = await getBankByName(bankName);
            console.log("Branches Data : ", data);
            setBranchData(data.docs);
            dispatch({ type: 'LOAN', payload: data });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const fetchLoanData = async (branch) => {
        setIsLoading(true);
        try {
            const data = await fetchLoanDetails(branch);
            console.log("Branches Data : ", data);
            setLoanData(data.docs);
            setPagination({
                totalPages: data.totalPages,
                page: data.page,
                hasNextPage: data.hasNextPage,
                hasPrevPage: data.hasPrevPage
            });
            dispatch({ type: 'LOAN', payload: data });
            setIsLoading(false);
            setShowDetails(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleBankChanges = async (event) => {
        if (event.target.value !== 'default') {
            await fetchBranchesData(event.target.value);
            setBankName(event.target.value);
        } else {
            setBranchData([]);
        }
    };

    const handleBranchChanges = async (event) => {
        if (event.target.value !== 'default') {
            await fetchLoanData(event.target.value);
        } else {
            setBranchData([]);
        }
    };

    const handleAddCollateral = async (values) => {
        const newCollateral = {
            detail: values.particulars,
            value: values.valueOfSecurityInterest,
            asset: values.assetType,
        };

        const additionalCollatorals = values.additionalCollatorals.map(item => ({
            detail: item.securityDetails,
            value: item.valueofSecurityInterest,
            asset: item.assetType,
        }));

        let isCollateralExists = false;
        if (collatorals.length > 0) {
            isCollateralExists = collatorals.some(collateral =>
                collateral.detail === newCollateral.detail &&
                collateral.value === newCollateral.value &&
                collateral.asset === newCollateral.asset
            );
        }
        if (!isCollateralExists) {
            console.log('Before update:', collatorals);
            setcollatorals(prevState => [
                ...prevState,
                newCollateral,
                ...additionalCollatorals
            ]);
            console.log('After update:', collatorals);
        }
        await handleSubmit(values);
    };

    const handleSubmit = async (values) => {
        if (operation === "update") {
            handleUpdate(values);
        } else {
            setAddLoading(true);
            const newPositionsArray = positions.map(({ id, ...rest }) => rest);
            await setcollatorals(prevCollatorals => {
                try {
                    dispatch(addLoanDetails({ formData: values, position: newPositionsArray, collateral: prevCollatorals }));
                } catch (error) {
                    console.error('Error adding user:', error);
                    showFailed("Failed to add user. Please try again later.");
                }
                return prevCollatorals;
            });
            setAddLoading(false)
            setPreviewOpen(false);
        }
    }

    const handleUpdate = async (values) => {
        const newPositionsArray = positions.map(({ id, ...rest }) => rest);
        await setcollatorals(prevCollatorals => {
            try {
                dispatch(updateLoanDetails({ formData: values, position: newPositionsArray, collateral: prevCollatorals, id: loanId }));
            } catch (error) {
                console.error('Error adding user:', error);
                showFailed("Failed to add user. Please try again later.");
            }
            return prevCollatorals;
        });
    }

    const handleAddLoan = () => {
        setOperation('Add');
        setPreviewOpen(true);
        setPositions([])
        setcollatorals([]);
    }

    const handleEdit = (_id) => {
        setOperation('update');
        setPreviewOpen(true);
        setLoanId(_id);
        setIsEditing(true);
        setPositions([])
        setcollatorals([]);
    }

    const handleClose = () => {
        setPreviewOpen(false);
    }

    console.log("Positions Array: ", positions);

    const goToNextPage = async () => {
        const nextPageIndex = pagination.page + 1;
        if (nextPageIndex <= pagination.totalPages) {
            try {
                const data = await loanPagination(nextPageIndex);
                setLoanData(data.docs);
                setPagination(prevState => ({
                    ...prevState,
                    page: nextPageIndex,
                    hasNextPage: data.hasNextPage,
                    hasPrevPage: data.hasPrevPage
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    const goToPrevPage = async () => {
        const prevPageIndex = pagination.page - 1;
        if (prevPageIndex >= 1) {
            try {
                const data = await loanPagination(prevPageIndex);
                setLoanData(data.docs);
                setPagination(prevState => ({
                    ...prevState,
                    page: prevPageIndex,
                    hasNextPage: data.hasNextPage,
                    hasPrevPage: data.hasPrevPage
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Borrower Name",
                accessor: (row) => {
                    const borrowerPosition = row.positions?.find(
                        (position) => position.role === "borrower"
                    );
                    if (borrowerPosition) {
                        if (borrowerPosition?.user) {
                            return `${borrowerPosition?.user?.first_name} ${borrowerPosition?.user?.last_name}`;
                        } else if (borrowerPosition?.organization) {
                            return borrowerPosition?.organization?.name;
                        }
                    }
                    return "";
                },
            },
            {
                Header: "System ID",
                accessor: (row) => {
                    const borrowerPosition = row.positions?.find(
                        (position) => position.role === "borrower"
                    );
                    if (borrowerPosition) {
                        if (borrowerPosition?.user) {
                            return `${borrowerPosition?.user?.system_id}`;
                        } else if (borrowerPosition?.organization) {
                            return borrowerPosition?.organization?.system_id;
                        }
                    }
                    return "";
                },
            },
            {
                Header: "Loan Account Number",
                accessor: "identity_number",
            },
            {
                Header: "Total Loan Amount",
                accessor: "amount",
            },
            {
                Header: "Tenure (in months)",
                accessor: "tenure.month",
            },
            {
                Header: 'Action',
                accessor: 'edit',
                Cell: ({ row }) => (
                    <button
                        className='border bg-bgclr text-white px-2'
                        onClick={() => handleEdit(row.original._id)}
                    >
                        Edit
                    </button>
                ),
            },
        ],
        []
    );


    const data = React.useMemo(() => loanData);

    return (
        <>
            <div className="flex justify-end bg-gray-200 min-h-screen py-10">
                <div className="rounded-md w-3/4 ml-32">
                    <div className='flex'>
                        <h1 className='text-3xl font-bold'>Loan Management</h1>
                    </div>
                    <div className='flex mt-3 gap-10'>
                        <div className='flex mt-10 h-14 items-end'>
                            <Button1 label="+Add Bank"
                                onClick={handleAddLoan}
                            />
                        </div>
                        <div className="mb-4 mt-4">
                            <label htmlFor="bankName" className="pr-12">
                                Select Bank
                            </label>
                            <select
                                as="select"
                                id="bankName"
                                name="bankName"
                                className="input-style rounded-md"
                                onChange={handleBankChanges}
                            >
                                <option value="default">Select a bank</option>
                                {options?.map((option) => (
                                    <option key={option?._id} value={option?._id}>
                                        {option?.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4 mt-4">
                            <label htmlFor="branchName" className="pr-12">
                                Select Branch
                            </label>
                            <select
                                as="select"
                                id="branchName"
                                name="branchName"
                                className="input-style rounded-md"
                                onChange={handleBranchChanges}
                            >
                                <option value="">Select a branch</option>
                                {branchData?.map((option) => (
                                    <option key={option?._id} value={option?._id}>
                                        {option?.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {!error ? (
                        <div>
                            {isLoading && (
                                <div className="-mt-36 -ml-32">
                                    <Loader />
                                </div>
                            )}
                            {showDetails ? (
                                <div className="w-[975px] mt-6 mb-20">
                                    <div className="pt-3 overflow-x-auto mb-10">
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                        />
                                    </div>
                                    <Pagination
                                        hasPrevPage={pagination.hasPrevPage}
                                        hasNextPage={pagination.hasNextPage}
                                        goToPrevPage={goToPrevPage}
                                        goToNextPage={goToNextPage}
                                    />
                                </div>
                            ) : (
                                <div className='mt-10'>
                                    <EmptyDataAlert title="Choose Bank and Branch for the Loan details." />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='flex ml-80 mt-5'>
                            <h1 className='font-bold text-3xl text-gray-500'>{error}</h1>
                        </div>
                    )}
                </div>
            </div>

            {isPreviewOpen && (
                <LoanAction
                    isPreviewOpen={isPreviewOpen}
                    handleClose={handleClose}
                    options={options}
                    handleBankChanges={handleBankChanges}
                    branchData={branchData}
                    handleSubmit={handleAddCollateral}
                    positions={positions}
                    setPositions={setPositions}
                    collatorals={collatorals}
                    setcollatorals={setcollatorals}
                    addLoading={addLoading}
                    loanId={loanId}
                    operation={operation}
                    isEditing={isEditing}
                    handleUpdate={handleUpdate}
                />
            )}
        </>
    )
}

export default LoanMapTable