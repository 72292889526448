import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react'
import { useTable } from "react-table";
import { Button1, Button3 } from '../../../../CommonComponents/Button';
import DateRangePicker from '../../../../CommonComponents/DateRangePicker';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineDateRange } from 'react-icons/md';
import AddIndividual from './AddIndividual';
import { addIndividual, updateIndividual } from '../../../../../Reducer/UserReducer';
import UpdateIndividual from './UpdateIndividual';
import Loader from '../../../../CommonComponents/Loader';
import { fetchIndividualData } from '../../../../../Services/Borrower';
import { individualPagination } from '../../../../../Services/Pagination';
import DataTable from '../../../../CommonComponents/DataTable';
import Pagination from '../../../../CommonComponents/Pagination';

const BorrowerIndividual = () => {

    const dispatch = useDispatch();

    const [users, setUsers] = useState([]);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isPreviewOpen, setPreviewOpen] = useState(false);
    const [isUpdateOpen, setIsUpdateOpen] = useState(false);
    const [rangeStart, setRangeStart] = useState(new Date());
    const [rangeEnd, setRangeEnd] = useState(() => {
        const defaultEndDate = new Date();
        defaultEndDate.setDate(defaultEndDate.getDate());
        return defaultEndDate;
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [pagination, setPagination] = useState(null);
    const [userId, setUserId] = useState();
    const [error, setError] = useState('');

    console.log("PAgination Data -------> ", pagination);

    const formData = {
        first_name: '',
        middle_name: '',
        last_name: '',
        gender: '',
        aadhaarNumber: '',
        panNumber: '',
        email: '',
        phone: '',
        whatsappNumber: '',
        current_address: '',
        permanent_address: '',
        alternate_number: '',
        alternate_email: ''
    };

    const fetchData = async () => {
        setFetchLoading(true);
        try {
            const data = await fetchIndividualData(searchQuery);
            console.log("Data is here: ",data)
            setUsers(data.docs);
            setPagination({
                totalPages: data.totalPages,
                page: data.page,
                hasNextPage: data.hasNextPage,
                hasPrevPage: data.hasPrevPage
            });
            dispatch({ type: 'USER', payload: data });
            setIsLoading(false);
            setFetchLoading(false);
        } catch (error) {
            console.log("error in individual: ",error);
            setError(error[0])
            setIsLoading(false);
            setFetchLoading(false);
        }
    };

    // Pagination functions
    const goToNextPage = async () => {
        const nextPageIndex = pagination.page + 1;
        if (nextPageIndex <= pagination.totalPages) {
            try {
                const data = await individualPagination(nextPageIndex);
                setUsers(data.docs);
                setPagination(prevState => ({
                    ...prevState,
                    page: nextPageIndex,
                    hasNextPage: data.hasNextPage,
                    hasPrevPage: data.hasPrevPage
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    const goToPrevPage = async () => {
        const prevPageIndex = pagination.page - 1;
        if (prevPageIndex >= 1) {
            try {
                const data = await individualPagination(prevPageIndex);
                setUsers(data.docs);
                setPagination(prevState => ({
                    ...prevState,
                    page: prevPageIndex,
                    hasNextPage: data.hasNextPage,
                    hasPrevPage: data.hasPrevPage
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    useEffect(() => {
        fetchData()
    }, [searchQuery]);

    const today = new Date();

    const selectStartDate = (date) => {
        setRangeStart(date);
        setRangeEnd(date);
    };

    const selectEndDate = (date) => {
        setRangeEnd(date);
    };

    const handleEdit = (_id) => {
        console.log("Edit Action triggerd", _id);
        setIsUpdateOpen(true);
        setUserId(_id)
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: row => `${row.first_name} ${row.middle_name} ${row.last_name}`
            },
            {
                Header: 'UCID',
                accessor: 'system_id'
            },
            {
                Header: 'Aadhaar Number',
                accessor: row => row.personal && row.personal.adhaar_card && row.personal.adhaar_card.number
            },
            {
                Header: 'PAN Card',
                accessor: row => row.personal && row.personal.pancard
            },
            {
                Header: 'Linked Loan',
                accessor: 'linkedLoans',
                Header: 'Linked Loan',
                accessor: 'linkedLoans',
                Cell: ({ row }) => (
                    <div className="flex flex-col items-center">
                        {row.original.loans ? row.original.loans.map((loan, index) => (
                            <span key={index}>{loan.identity_number}</span>
                        )) : null}
                    </div>
                )
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: ({ row }) => (
                    <div className="flex my-2">
                        <Button3
                            label="Edit"
                            onClick={() => handleEdit(row.original._id)}
                        />
                    </div>
                )
            },
        ],
        []);

    const data = React.useMemo(() => users);

    console.log("Print the Data => ", data);

    const handleClose = async () => {
        setPreviewOpen(false);
    }

    const handleAddUser = () => {
        setPreviewOpen(true);
    }

    const handleSubmit = (values) => {
        console.log("------> Add user ")
        dispatch(addIndividual(values));
        setPreviewOpen(false);
        setFetchLoading(true);
        setTimeout(() => {
            fetchData();
        }, 2000);
    };

    const handleUpdateClose = async () => {
        setIsUpdateOpen(false);
    }

    const handleUpdate = (values) => {
        dispatch(updateIndividual({ formData: values, userId: userId }));
        setIsUpdateOpen(false);
        setFetchLoading(true);
        setTimeout(() => {
            fetchData();
        }, 500);
    };

    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            <div className="flex justify-end bg-gray-200 min-h-screen py-10">
                <div className="rounded-md w-3/4 ml-32">
                    <div className='flex'>
                        <h1 className='text-3xl font-bold'>Borrower Details ( Individual )</h1>
                    </div>
                    <div className='flex gap-5 mt-5'>
                        <div className='flex mt-5'>
                            <Button1 label="+Add User" onClick={handleAddUser} />
                        </div>
                        <div className='flex'>
                            <div className='flex  items-center ml-20'>
                                <div>
                                    <DateRangePicker
                                        // selectedDate={rangeStart}
                                        onChange={selectStartDate}
                                        selectsStart
                                        startDate={rangeStart}
                                        endDate={today}
                                        maxDate={today}
                                        placeholder="DD/MM/YYYY"
                                    />
                                </div>
                                <div className='date-icon'>
                                    <MdOutlineDateRange size={28} />
                                </div>
                            </div>
                            <div className='flex items-center'>
                                <div>
                                    <DateRangePicker
                                        // selectedDate={rangeEnd}
                                        onChange={selectEndDate}
                                        startDate={rangeStart}
                                        endDate={rangeEnd}
                                        minDate={rangeStart}
                                        maxDate={today}
                                        selectsEnd
                                        placeholder="DD/MM/YYYY"
                                    />
                                </div>
                                <div className='date-icon'>
                                    <MdOutlineDateRange size={28} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex'>
                        <input
                            type="text"
                            placeholder="Search by Name/Email"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="border border-bgclr3 rounded-md w-[855px] pl-6 pr-5 py-1 mr-2 mb-4 mt-4"
                        />
                    </div>
                    {!error ? (
                        <div>
                            {fetchLoading ? (
                                <div className="-mt-36 -ml-32">
                                    <Loader />
                                </div>
                            ) : (
                                <div className="w-[975px] mt-6 mb-20">
                                    <div className="pt-3 overflow-x-auto mb-10">
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                        />
                                    </div>
                                    <Pagination
                                        hasPrevPage={pagination.hasPrevPage}
                                        hasNextPage={pagination.hasNextPage}
                                        goToPrevPage={goToPrevPage}
                                        goToNextPage={goToNextPage}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='flex ml-80 mt-5'>
                            <h1 className='font-bold text-3xl text-gray-500'>{error}</h1>
                        </div>
                    )}
                </div>
            </div>

            {isPreviewOpen && (
                <AddIndividual
                    isPreviewOpen={isPreviewOpen}
                    onClose={() => setPreviewOpen(false)}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    formData={formData}
                />
            )}

            {isUpdateOpen && (
                <UpdateIndividual
                    isOpen={isUpdateOpen}
                    onClose={() => setIsUpdateOpen(false)}
                    handleClose={handleUpdateClose}
                    handleUpdate={handleUpdate}
                    formData={formData}
                    userId={userId}
                />
            )}

        </>
    )
}

export default BorrowerIndividual