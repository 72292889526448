import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react'
import Loader from '../../../CommonComponents/Loader';
import { Button1, Button3 } from '../../../CommonComponents/Button';
import { bankUserPagination, cmsUserPagination } from '../../../../Services/Pagination';
import { showFailed } from '../../../CommonComponents/SweetAlert';
import CredentialAction from './CredentialAction';
import { getBankByName, getBankList } from '../../../../Services/BranchOnboarding';
import { getBankRoles } from '../../../../Services/BankCredential';
import { addBankUser, updateBankUser } from '../../../../Reducer/BankIdReducer';
import DataTable from '../../../CommonComponents/DataTable';
import Pagination from '../../../CommonComponents/Pagination';

const CredentialTable = () => {

    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
    const [operation, setOperation] = useState('add');
    const [isPreviewOpen, setPreviewOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [bankId, setBankId] = useState();
    const [error, setError] = useState('');
    const [selectedBank, setSelectedBank] = useState([]);
    const formData = {
        bankName: '',
        branchName: '',
        name: '',
        email: '',
        status: '',
        password: ''
    };

    useEffect(() => {
        const fetchData = async (searchQuery) => {
            try {
                const data = await getBankList(searchQuery);
                setOptions(data);
                console.log("Roles Data is here:---", data);
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    showFailed('Failed...', `${error.response.data.error[0]}`)
                    console.log("Bad Request Error: ", error.response.data);
                } else {
                    showFailed('Failed...', `${error.response.data.error[0]}`)
                    console.error('Error fetching data:', error);
                }
            }
        };
        fetchData();
    }, [])

    const fetchCredential = async (bank) => {
        setIsLoading(true)
        setError('');
        setUsers([]);
        try {
            const data = await getBankRoles(bank);
            console.log("Branches Data : ", data);
            setUsers(data.docs);
            setPagination({
                totalPages: data.totalPages,
                page: data.page,
                hasNextPage: data.hasNextPage,
                hasPrevPage: data.hasPrevPage
            });
            dispatch({ type: 'BANKUSER', payload: data });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false)
            if (error.response && error.response.status === 400) {
                setError(error.response.data.error[0])
                console.error('Error fetching Credential :', error.response.data.error[0]);
            } else {
                console.error('Error fetching data:', error);
            }
        }
    }

    const fetchBranchesData = async (bankName) => {
        setError(null);
        setSelectedBank([]);
        try {
            const data = await getBankByName(bankName);
            console.log("Branches Data : ", data);
            setSelectedBank(data.docs);
            // dispatch({ type: 'BANKUSER', payload: data });
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setError(error.response.data.error[0])
            } else {
                console.error('Error fetching data:', error);
            }
        }
    }

    const goToNextPage = async () => {
        const nextPageIndex = pagination.page + 1;
        if (nextPageIndex <= pagination.totalPages) {
            try {
                const data = await bankUserPagination(nextPageIndex);
                setUsers(data.docs);
                setPagination(prevState => ({
                    ...prevState,
                    page: nextPageIndex,
                    hasNextPage: data.hasNextPage,
                    hasPrevPage: data.hasPrevPage
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    const goToPrevPage = async () => {
        const prevPageIndex = pagination.page - 1;
        if (prevPageIndex >= 1) {
            try {
                const data = await bankUserPagination(prevPageIndex);
                setUsers(data.docs);
                setPagination(prevState => ({
                    ...prevState,
                    page: prevPageIndex, // Update page in pagination state
                    hasNextPage: data.hasNextPage,
                    hasPrevPage: data.hasPrevPage
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    const handleEdit = (_id) => {
        console.log("Edit Action triggerd", _id);
        setOperation('update');
        setPreviewOpen(true);
        setBankId(_id)
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'User ID',
                accessor: 'system_id'
            },
            {
                Header: 'Branch Name',
                accessor: 'branch.name'
            },
            {
                Header: 'Status',
                accessor: 'status'
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: ({ row }) => (
                    <div className="flex my-2 justify-center">
                        <Button3
                            label="Edit"
                            onClick={() => handleEdit(row.original._id)}
                        />
                    </div>
                )
            },
        ],
        []);

    const data = React.useMemo(() => users);

    const handleDropdownChange = async (event) => {
        await fetchBranchesData(event.target.value);
    }
    const handleBankChanges = async (event) => {
        await fetchCredential(event.target.value);
    }

    const handleClose = async () => {
        setPreviewOpen(false);
    }

    const handleAddUser = () => {
        console.log("Add User Click");
        setOperation('Add');
        setPreviewOpen(true);
    }

    const handleSubmit = (values) => {
        console.log("------> Add Bank ", values);
        try {
            dispatch(addBankUser(values));
            setPreviewOpen(false);
            setTimeout(async () => {
                await fetchCredential(values.bankName);
            }, 2000);
        } catch (error) {
            console.error('Error adding user:', error);
            showFailed("Failed to add user. Please try again later.");
        }
    };

    const handleUpdate = (values) => {
        console.log("Updated bank Data: ", values);
        // setIsLoadingUpdate(true);
        dispatch(updateBankUser({ formData: values, bankId: bankId }));
        handleClose();
        setTimeout(async () => {
            await fetchCredential(values.bankName);
        }, 500);
    }

    return (
        <>
            <div className="flex justify-end bg-gray-200 min-h-screen py-10">
                <div className="rounded-md w-3/4 ml-32">
                    <div className='flex'>
                        <h1 className='text-3xl font-bold'>Add additional bank user</h1>
                    </div>
                    <div className='flex mt-3 gap-10'>
                        <div className='flex mt-10 h-14 items-end'>
                            <Button1 label="+Add Bank" onClick={handleAddUser} />
                        </div>
                        <div className="mb-4 mt-4">
                            <label htmlFor="bankName" className="pr-12">
                                Select Bank
                            </label>
                            <select
                                as="select"
                                id="bankName"
                                name="bankName"
                                className="input-style rounded-md"
                                onChange={handleBankChanges}
                            >
                                <option value="">Select a bank</option>
                                {options?.map((option) => (
                                    <option key={option?._id} value={option?._id}>
                                        {option?.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {!error ? (
                        <div>
                            {isLoading && (
                                <div className="-mt-36 -ml-32">
                                    <Loader />
                                </div>
                            )}
                            <div className="w-[975px] mt-6 mb-20">
                                <div className="pt-3 overflow-x-auto mb-10">
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                    />
                                </div>
                                <Pagination
                                    // hasPrevPage={pagination.hasPrevPage}
                                    // hasNextPage={pagination.hasNextPage}
                                    goToPrevPage={goToPrevPage}
                                    goToNextPage={goToNextPage}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='flex ml-80 mt-5'>
                            <h1 className='font-bold text-3xl text-gray-500'>{error}</h1>
                        </div>
                    )}
                </div>
            </div>

            {isPreviewOpen && (
                <CredentialAction
                    isPreviewOpen={isPreviewOpen}
                    onClose={() => setPreviewOpen(false)}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    handleUpdate={handleUpdate}
                    formData={formData}
                    isLoadingUpdate={isLoadingUpdate}
                    bankId={bankId}
                    operation={operation}
                    options={options}
                    selectedBank={selectedBank}
                    fetchBranchesData={fetchBranchesData}
                    handleDropdownChange={handleDropdownChange}
                />
            )}

        </>
    )
}

export default CredentialTable;