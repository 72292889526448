import { showToast } from "../Components/CommonComponents/ShowToast";
import { showFailed } from "../Components/CommonComponents/SweetAlert";
import axiosInstance from "../Interceptor/AxiosInstanceInterceptor";
import { camelToSnakeCase } from "../Reducer/EntityReducer";


export const fetchIndividualData = async (searchQuery) => {
  try {
    const response = await axiosInstance.get(`/user?search=${searchQuery}`);
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      showFailed('Failed...www', `${response.error[0]}`)
    }
  } catch (error) {
    console.log("Individual Error: ", error?.response?.data?.error[0]);
    showFailed('Failed...fqewfqewfqf', `${error?.response?.data?.error[0]}`)
  }
};

// Add Individual Borrower
export const addIndividualData = async (formData) => {
  console.log("Updated Data hitted ---> ", formData);
  const updatedData = {
    email: formData.email,
    first_name: formData.first_name,
    middle_name: formData.middle_name,
    last_name: formData.last_name,
    phone_number: formData.phone,
    role: "user",
    gender: formData.gender,
    personal: {
      adhaar_card: {
        digits: null,
        number: formData.aadhaarNumber
      },
      whatsapp: formData.whatsappNumber,
      pancard: formData.panNumber,
    },
    address: {
      country: 'India',
      state: formData.state_name,
      city: formData.city_name,
      country_code: 101,
      state_code: formData.state_code,
      city_code: formData.city_code,
      current_address: formData.current_address,
      permanent_address: formData.permanent_address,
    },
    alternate_contacts: {
      email: formData.alternate_email,
      phone_number: formData.alternate_number,
    },

  };

  try {
    const response = await axiosInstance.post(`/user/create`, updatedData);
    const data = response.data;
    console.log("Added individual user - ", data);
    if (response.status === 200) {
      return data;
    } else {
      showFailed('Failed...', `${response.status}`);
      throw new Error("Failed to fetch data. Status: " + response.status);
    }
  } catch (error) {
    console.error("Error Retrieving CMS User Data", error);
    showFailed('Failed...', `${error.response.data.error[0]}`);
  }

}

// Update Individual Data
export const updateIndividualData = async (formData, userId) => {
  console.log("------------------first")
  const updatedData = {
    email: formData.email,
    first_name: formData.first_name,
    middle_name: formData.middle_name,
    last_name: formData.last_name,
    phone_number: formData.phone,
    role: "user",
    gender: formData.gender,
    personal: {
      adhaar_card: {
        digits: null,
        number: formData.aadhaarNumber
      },
      whatsapp: formData.whatsappNumber,
      pancard: formData.panNumber,
    },
    address: {
      country: "India",
      state: formData.state_name,
      city: formData.city_name,
      country_code: 101,
      state_code: formData.state_code,
      city_code: formData.city_code,
      current_address: formData.current_address,
      permanent_address: formData.permanent_address,
    },
    alternate_contacts: {
      email: formData.alternate_email,
      phone_number: formData.alternate_number,
    },

  };
  try {
    const response = await axiosInstance.patch(`/user/${userId}`, updatedData);
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      showFailed('Failed...', `${response.status}`);
      throw new Error("Failed to fetch data. Status: " + response.status);
    }
  } catch (error) {
    console.error("Error Retrieving CMS User Data", error);
    showFailed('Failed...', `${error.response.data.error[0]}`);
  }
};



// Organization


export const fetchOrganizationData = async (searchQuery) => {
  try {
    const response = await axiosInstance.get(`/organization?search=${searchQuery}`);

    if (response.status === 200) {
      const data = await response.data;
      return data;
    } else {
      throw new Error(`Failed to fetch user data: ${response.statusText}`);
    }
  } catch (error) {
    throw new Error('Error retrieving user data:', error);
  }
};


// Add Organization Borrower
export const addOrganizationData = async (formData) => {
  console.log("Updated Data hitted ---> ", formData.type);
  const updatedData = {
    email: formData.email,
    name: formData.name,
    type: camelToSnakeCase(formData.type),
    role: "entity",
    phone_number: formData.phone,
    authorized_person: formData.authorized_person,
    designation: formData.designation,
    personal: {
      gst_number: formData.gstNumber,
      pancard: formData.panNumber,
    },
    address: {
      country: 'India',
      state: formData.state_name,
      city: formData.city_name,
      office_address: formData.office_address,
      registered_address: formData.registered_address,
    },
    alternate_contacts: {
      email: formData.alternate_email,
      phone_number: formData.alternate_number,
    },

  };

  try {
    const response = await axiosInstance.post(`/user/create`, updatedData);
    const data = response.data;
    console.log("Borrower user added - ", data)
    if (response.status === 200) {
      showToast('Organization Data Added Successfully', 'success');
      return data;
    } else {
      showFailed('Failed...', `${response.status}`);
      console.error('Failed to update user data');
    }
  } catch (error) {
    console.error('Error occurred while updating user data:', error);
    showFailed('Failed...', `${error.response.data.error[0]}`);
  }

}

// Update Individual Data
export const updateOrganizationData = async (formData, userId) => {
  console.log("------------------first")
  const updatedData = {
    email: formData.email,
    name: formData.name,
    type: formData.type,
    role: "entity",
    phone_number: formData.phone,
    authorized_person: formData.authorized_person,
    designation: formData.designation,
    personal: {
      gst_number: formData.gstNumber,
      pancard: formData.panNumber,
    },
    address: {
      country: "India",
      state: formData.state_name,
      city: formData.city_name,
      country_code: 101,
      state_code: formData.state_code,
      city_code: formData.city_code,
      office_address: formData.office_address,
      registered_address: formData.registered_address,
    },
    alternate_contacts: {
      email: formData.alternate_email,
      phone_number: formData.alternate_number,
    },

  };

  try {
    const response = await axiosInstance.patch(`/organization/${userId}`, updatedData);
    const data = response.data;

    if (response.status === 200) {
      showToast('Organization Data is updated successfully', 'success');
      return data;
    } else {
      showFailed('Failed...', `${response.status}`);
    }
  } catch (error) {
    showFailed('Failed...', `${error.response.data.error[0]}`);
  }
};