import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react'
import { useTable } from "react-table";
import Loader from '../../../CommonComponents/Loader';
import { Button1, Button3, Button4 } from '../../../CommonComponents/Button';
import DateRangePicker from '../../../CommonComponents/DateRangePicker';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineDateRange } from 'react-icons/md';
import UserActions from './UserActions';
import { addCMSUser, deleteCMSUser, updateCMSUser } from '../../../../Reducer/UserManagementReducer';
import { fetchCMSUser } from '../../../../Services/CmsUserManagement';
import { cmsUserPagination } from '../../../../Services/Pagination';
import { showDeleteAlert, showFailed } from '../../../CommonComponents/SweetAlert';
import Swal from 'sweetalert2';
import { fetchRole } from '../../../../Services/RoleManagement';
import DataTable from '../../../CommonComponents/DataTable';
import Pagination from '../../../CommonComponents/Pagination';

const UserRegistration = () => {

  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
  const [operation, setOperation] = useState('add');
  const [isLoading, setIsLoading] = useState(true);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [rangeStart, setRangeStart] = useState(new Date());
  const [rangeEnd, setRangeEnd] = useState(() => {
    const defaultEndDate = new Date();
    defaultEndDate.setDate(defaultEndDate.getDate());
    return defaultEndDate;
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [pagination, setPagination] = useState(null);
  const [userId, setUserId] = useState();
  const [error, setError] = useState();
  const [roles, setRoles] = useState([]);

  console.log("PAgination Data -------> ", pagination);

  const formData = {
    email: '',
    phone_number: '',
    name: '',
    gender: '',
    role: ''
  };

  const fetchCMSData = async () => {
    console.log("Search Query : ", searchQuery);
    setError(null);
    setFetchLoading(true);
    try {
      const data = await fetchCMSUser(searchQuery);
      setUsers(data.docs);
      setPagination({
        totalPages: data.totalPages,
        page: data.page,
        hasNextPage: data.hasNextPage,
        hasPrevPage: data.hasPrevPage
      });
      dispatch({ type: 'CMSUSER', payload: data });
      setIsLoading(false);
      setFetchLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log("Bad Request Error: ", error.response.data);
        setError(error.response.data.error[0])
      } else {
        console.error('Error fetching data:', error);
      }
      setIsLoading(false);
      setFetchLoading(false);
    }
  };

  const fetchData = async (searchQuery) => {
    try {
      const data = await fetchRole(searchQuery);
      console.log("--->", data);
      setRoles(data.docs);
      console.log("Roles Data is here:---", data.docs);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        showFailed('Failed...', `${error.response.data.error[0]}`)
        console.log("Bad Request Error: ", error.response.data);
      } else {
        showFailed('Failed...', `${error.response.data.error[0]}`)
        console.error('Error fetching data:', error);
      }
    }
  };

  useEffect(() => {
    fetchData();
    fetchCMSData();
  }, [searchQuery])


  const goToNextPage = async () => {
    const nextPageIndex = pagination.page + 1;
    if (nextPageIndex <= pagination.totalPages) {
      try {
        const data = await cmsUserPagination(nextPageIndex);
        setUsers(data.docs);
        setPagination(prevState => ({
          ...prevState,
          page: nextPageIndex,
          hasNextPage: data.hasNextPage,
          hasPrevPage: data.hasPrevPage
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const goToPrevPage = async () => {
    const prevPageIndex = pagination.page - 1; // Use pagination state
    if (prevPageIndex >= 1) { // Fix comparison
      try {
        const data = await cmsUserPagination(prevPageIndex); // Pass prevPageIndex
        setUsers(data.docs);
        setPagination(prevState => ({
          ...prevState,
          page: prevPageIndex, // Update page in pagination state
          hasNextPage: data.hasNextPage,
          hasPrevPage: data.hasPrevPage
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const today = new Date();

  const selectStartDate = (date) => {
    setRangeStart(date);
    setRangeEnd(date);
  };

  const selectEndDate = (date) => {
    setRangeEnd(date);
  };

  const handleEdit = (_id) => {
    console.log("Edit Action triggerd", _id);
    setOperation('update');
    setPreviewOpen(true);
    setUserId(_id)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Email ID',
        accessor: 'email'
      },
      {
        Header: 'Phone Number',
        accessor: 'phone_number'
      },
      {
        Header: 'Person Name',
        accessor: 'name'
      },
      {
        Header: 'Gender',
        accessor: 'gender'
      },
      {
        Header: 'Role',
        accessor: 'role'
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => (
          <div className="flex my-2">
            <Button3
              label="Edit"
              onClick={() => handleEdit(row.original._id)}
            /> | <Button4
              label='Delete'
              onClick={() => handleDelete(row.original._id)}
            />
          </div>
        )
      },
    ],
    []);

  const data = React.useMemo(() => users);

  console.log("Print the Data => ", data);

  const handleClose = async () => {
    setPreviewOpen(false);
  }

  const handleAddUser = () => {
    console.log("Add User Click");
    setOperation('Add');
    setPreviewOpen(true);
  }

  const handleSubmit = (values, permissions) => {
    console.log("------> Add user ", values);
    try {
      dispatch(addCMSUser({ formData: values, permissions: permissions }));
      setPreviewOpen(false);
      setFetchLoading(true);
      setTimeout(async () => {
        await fetchCMSData();
      }, 2000);
    } catch (error) {
      console.error('Error adding user:', error);
      showFailed("Failed to add user. Please try again later.");
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleUpdate = (values, permissions) => {
    setIsLoadingUpdate(true);
    dispatch(updateCMSUser({ formData: values, userId: userId, permissions: permissions }));
    handleClose();
    setFetchLoading(true);
    setTimeout(() => {
      fetchCMSData();
      setIsLoadingUpdate(false);
    }, 500)
  }

  const handleDelete = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1D4ED8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        setFetchLoading(true);
        dispatch(deleteCMSUser(_id));
        setTimeout(() => {
          Swal.fire({
            title: "Deleted!",
            text: "CMS User has been deleted.",
            icon: "success"
          });
          fetchCMSData();
        }, 2000);
      }
    });
  };

  // Use the useTable hook to create the table instance
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <div className="flex justify-end bg-gray-200 min-h-screen py-10">
        <div className="rounded-md w-3/4 ml-32">
          <div className='flex'>
            <h1 className='text-3xl font-bold'>CMS User Data</h1>
          </div>
          <div className='flex gap-5 mt-5'>
            <div className='flex mt-5'>
              <Button1 label="+Add User" onClick={handleAddUser} />
            </div>
          </div>

          <div className='flex'>
            <input
              type="text"
              placeholder="Search by Name/Email"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="border border-bgclr3 rounded-md w-[890px] pl-6 pr-5 py-1 mr-2 mb-4 mt-4"
            />
          </div>
          {!error ? (
            <div>
              {fetchLoading ? (
                <div className="-mt-36 -ml-32">
                  <Loader />
                </div>
              ) : (
                <div className="w-[975px] mt-6 mb-20">
                  <div className="pt-3 overflow-x-auto mb-10">
                    <DataTable
                      columns={columns}
                      data={data}
                    />
                  </div>
                  <Pagination
                    // hasPrevPage={pagination.hasPrevPage}
                    // hasNextPage={pagination.hasNextPage}
                    // goToPrevPage={goToPrevPage}
                    // goToNextPage={goToNextPage}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className='flex ml-80 mt-5'>
              <h1 className='font-bold text-3xl text-gray-500'>{error}</h1>
            </div>
          )}
        </div>
      </div>

      {isPreviewOpen && (
        <UserActions
          isPreviewOpen={isPreviewOpen}
          onClose={() => setPreviewOpen(false)}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          handleUpdate={handleUpdate}
          formData={formData}
          isLoadingUpdate={isLoadingUpdate}
          userId={userId}
          operation={operation}
          roles={roles}
        />
      )}

    </>
  )
}

export default UserRegistration